<template>
  <v-card
    width=100%
    height=100%
    flat
  >

 
   <v-card    
      width=100%
      flat
      >

  <div class="divhead" id="videos"> 

    <video class="rhvideo" id="remoteVideo" ref="remoteVideo" style="object-fit:fill" muted="true" preload="auto" autoplay="autoplay" x-webkit-airplay="true" playsinline ="true" webkit-playsinline ="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint">
      <canvas id="canvas"></canvas>   
    </video>
     <div class="arc" id="loading">     
     </div>    
        <v-btn @click="onback"  icon>
        <v-icon
              dark
              color="white"
        >mdi-arrow-left</v-icon>
      </v-btn>
  </div>


 </v-card>
      <v-card
          flat
          align="center"
        >
                <div class="divtools">  
                 </div>
         </v-card>
        <v-card
          flat
          align="center"
 
              
        >

            <v-img
              src="../assets/joystick_move.png"
              height="182px"
              width="182px" 
              
              
            
            >


            </v-img>

         </v-card>

  <v-toolbar
      absolute
      bottom      
      width=100%
    >
    <v-divider></v-divider>
   <v-card
        class="d-flex justify-space-between"
        width=100%
        flat  
      >
         
        <v-card
          class="d-flex flex-column text-center text-xl-body-1"
          width="33%"
          @click="setlocalspeak"
          flat
          tile
        >
        <v-icon
        :color="speak===true?'blue':'grey'"
        >{{iconspeak}}
        </v-icon>
        <span>Speek</span>  
        </v-card>
        <v-divider>vertical</v-divider>
        <v-card
          class="d-flex flex-column  text-center"
          width="33%"
          @click="setlocalaudio"
          flat
          tile 

        >
        <v-icon 
      
        :color="mute===true?'blue':'grey'"
        >{{iconmute}}
        </v-icon>
        <span>Mic</span>
        
        </v-card>
        <v-divider>vertical</v-divider>
        <v-card
          class="d-flex flex-column text-center"
          width="33%"
          @click="shareCamera"
          flat
          tile
        >
        <v-icon
        :color="isWeixinminiProgram===true?'blue':'grey'"
        >mdi-share-variant</v-icon>
        <span>Share</span>
  
        
        </v-card>
      </v-card>
    </v-toolbar>

  </v-card>
  
</template>

<script>

  export default {
    

    data: () => ({
        videosheight:300,
        serno: '',  
        name: '',
        isAndroid:false,
        isIos:false,
        isChrome:false,
        isWeixin:false,
        isWeixinminiProgram:false,
        websock: null,
        showVideo:true,
        connected: false,
				connecting: false,
        videoplaying: false,
				socketTask: false,
				startRecive:false,
				StartCalled:false,
				CheckedgetUserMedia:false,
				IsWebSocketCreateed:false,
        IsWebSocketConnecting:false,
        IsWebSocketOpened:false,
				IsLocalAudioTrack:false,
				IsLocalVideoTrack:false,
				IsSystemAudioDeviceOK:false,
        IsFailCreate:false,
        recorded:false,
				speak:true,
        iconspeak:'mdi-volume-high',
        iconspeakcolor:'blue',
        mute:true,
        iconmute:'mdi-microphone',
				myPeerConnection:null,
				myDataChannel: null,
				stream:null,
				remoteVideo:null,
				heartCheck:null,
				messagecallback :null,
				RTCPeerConnectionCreated : false,
        peerid:'',
				meid:'',
				sessionId:'',
				wsurl:'',
				url:'',
        mymsg:false,
				roomId:'',
        IsReconnect:false,
        serverTimeoutObj: null,
        wxtitle:'',
        wxdesc:'',
        wxlink:'',
        wximgUrl:'',
        IceCandidate:new Array(),
				connectmode:"live",
				connectsource:"MainStream",
        configuration:{"iceServers": [{'urls': [ 'stun:webrtc.qq-kan.com:3478?transport=udp',]}]},
        jsApiList : ['onMenuShareQQ', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'updateAppMessageShareData', 'updateTimelineShareData'],
    }),
    created() {
       console.log("RealVideo created   "+this.$route.params.serno);
       this.serno= this.$route.params.serno;
       this.peerid =this.serno;
       this.name= this.$route.params.name;
       this.sessionId = this.newGuid();
       this.meid = this.global.getMeId();
       this.connectmode ="live";
       this.connectsource="MainStream"
       this.global.AddModeMap(this.sessionId,this.getMessage);

       var that = this;

       this.wx.miniProgram.getEnv(function(res) {
             that.isWeixinminiProgram = res.miniprogram
             console.log("RealVideo miniProgram  ------------------ ",res.miniprogram);
             if(res.miniprogram==true){
               
              document.addEventListener("WeixinJSBridgeReady", function () {
                     console.log("RealVideo WeixinJSBridgeReady  ------------------ ");
              },false);
              document.addEventListener("onPageStateChange", function () {
                    console.log("RealVideo onPageStateChange  ------------------ ");
              },false);
              document.addEventListener("onShareAppMessage", function (options) {
                    console.log("RealVideo onShareAppMessage  ------------------ ",options.webViewUrl);
              },false);

               if (window.WeixinJSBridge!= null){
                 console.log("RealVideo WeixinJSBridge   ------------------ >>>>>>>>>"); 
                  window.WeixinJSBridge.on('onPageStateChange', function(res) {
                        console.log('RealVideo miniProgram onPageStateChange  is active', res.active);
                        if(res.active== false){
                              that.onback();
                        }
                        
                })
               }
               

             }
             

      })


      
      //
    },
    mounted(){
       // console.log("RealVideo mounted  ------------------ ");

   

    
      var u = window.navigator.userAgent;
      if(u!=null){
        this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //ios终端
        this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        this.isChrome =u.indexOf("Chrome") !== -1 //Chrome 浏览器
        this.isWeixin = u.indexOf('micromessenger') != -1;  //微信浏览器
      }
       var loading = document.getElementById('loading'); 
       let videos = document.getElementById('videos');
       var clientWidth = document.documentElement.clientWidth;
       var clientHeight = document.documentElement.clientHeight;
       var videoheight = (9*document.documentElement.clientWidth/16);
       if(videoheight>clientHeight/2){
         videoheight = clientHeight/2

       }

       videos.style.width = clientWidth+'px';
       videos.style.height = videoheight+'px';


      loading.style.left= (document.documentElement.clientWidth/2)-25+'px';
      loading.style.top= ((videoheight)/2-25)+'px';

      this.remoteVideo = document.getElementById("remoteVideo");
      if(this.remoteVideo!= null){
        
        this.remoteVideo.muted = true;

        this.remoteVideo.style.width = clientWidth+'px';
       this.remoteVideo.style.height = videoheight+'px';
        
        this.remoteVideo.addEventListener('playing', this.OnVideoPlaying);
        this.remoteVideo.addEventListener('play', this.OnVideoPlay);
        this.remoteVideo.addEventListener('loadedmetadata', this.OnVideoLoadedMetaData);
        this.remoteVideo.addEventListener('canplay', this.OnVideoCanPlay);
        this.remoteVideo.addEventListener('error', this.OnVideoError);
        this.remoteVideo.addEventListener('loadeddata', this.OnVideoLoadedData);
      }
      let _this = this;//赋值vue的this
      window.onresize = ()=>{
        _this.pageResize();
      }
      this.stream = this.global.getStream();
      if(this.stream==null){
            this.iconmute='mdi-microphone-outline';
            this.NewgetUserMedia({video: false, audio: true},this.streamHandler,this.errorHandler);
      }else{
           this.IsLocalAudioTrack = true;
           this.CheckedgetUserMedia = true;
           this.Connect();
      }
      
      
    },
    destroyed() {
        //console.log("RealVideo destroyed   ");
        this.global.DelModeMap(this.sessionId);
        this.handleLeave();
        this.sendDisconnect();
         window.onresize = null;
    },
    methods: {
      getUrl () {
        if (window.entryUrl === '') {
          window.entryUrl = location.href.split('#')[0]
        }
        var u = navigator.userAgent
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // g
        return isAndroid ? location.href.split('#')[0] : window.entryUrl
      },
      getConfig () {
        var that = this;
        var url = this.getUrl()
        return new Promise((resolve, reject) => {
          that.$axios.get('your requestUrl', {
            params: {
              url: url
            }
          }).then((response) => {
            var data = response.data.data
            var appId = data.appId
            var noncestr = data.nonceStr
            // var jsapi_ticket = res.jsapi_ticket;
            var timestamp = data.timestamp
            var signature = data.signature
            that.wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: appId, // 必填，公众号的唯一标识
              timestamp: timestamp, // 必填，生成签名的时间戳
              nonceStr: noncestr, // 必填，生成签名的随机串
              signature: signature, // 必填，签名，见附录1
              jsApiList: that.jsApiList // 必填，需要使用的JS接口列表，所有JS接口列表 见附录2
            })
            that.wx.error(function (res) {
              console.log(JSON.stringify(res))
              reject()
            })
            resolve()
          })
        })
      },
      shareToFriendsCircle () {
         var that = this;
        this.wx.ready(() => {
          that.wx.onMenuShareTimeline({
            title: that.wxtitle,
            link: that.wxlink,
            imgUrl: that.wximgUrl,
            success: function () {
            }
          })
        })
      },
      shareToFriends () {
         var that = this;
        this.wx.ready(() => {
          that.wx.onMenuShareAppMessage({
            title: that.wxtitle,
            desc: that.wxdesc,
            link: that.wxlink,
            imgUrl: that.wximgUrl,
            success: function () {

            }
          })
        })
      },
      deletefun(){

      },
      getMessage: function(msg){
       
        if(msg!= null && msg.data!= null){
          if(msg.data.sessionId!= this.sessionId){
            return ;
          }
        }
        
        //console.log("RealVideo getMessage  ------------------- ",msg.eventName);
     
            
        switch(msg.eventName) {
										case "_create":
                      this.handleCreate(msg.data);
                       break;
										case "_offer":
                      this.handleOffer(msg.data);
                      break;
										case "_answer":
                       this.handleAnswer(msg.data);
                       break;
										case "_ice_candidate":
                      this.handleCandidate(msg.data);
                      break;
										case "_session_disconnected":
                      this.handleDisconnect(msg.data);
                      break;
										case "_post_message":
                      this.handlePostMessage(msg.data);
                      break;
										case "_connectinfo":
                      this.handleConnectInfo(msg.data);
                      break;
                    case "_session_failed":
                      this.handleSessionFailed(msg.data);
                     break;
                     case "_ping":
                     break;
                     default:
                       console.log("Got default message", msg);
                    break;
            }
            
      },

      onback: function(){
            
           this.$router.push("/")
      },
      newGuid() {
                let s4 = function() {
                    return (65536 * (1 + Math.random()) | 0).toString(16).substring(1);
                }
                return (s4() + s4() + "-" + s4() + "-4" + s4().substr(0, 3) + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
       },
       handlePostMessage(data){
            if(this.messagecallback===undefined  || this.messagecallback===null){
              console.log("handlePostMessage");
            }else{
              typeof(this.messagecallback)==='function'&&this.messagecallback(data.message);
              this.messagecallback = null;
              }  
			},
       handleSessionFailed(data){
        console.log("handleSessionFailed ",data);
        if(data.sessionId == this.sessionId){
          if(this.RTCPeerConnectionCreated == true){
              this.handleLeave();
              this.sendDisconnect();
          }         
         this.sessionId = this.newGuid();
        }
         
			},
      handleDisconnect(data) {
        if(data.sessionId == this.sessionId){
          console.log("handleDisconnect ", JSON.stringify(data));
          this.handleLeave();
          }
      },
      handleCreate(data) {
       // console.log("WebSocket _create", data);
         if (data.state === "online" || data.state === "sleep") {
            this.IsWebSocketCreateed = true;
             if (data.iceServers !== null || data.iceServers !== undefined || data.iceServers !== '') {
               //console.log("WebSocket iceServers", data.iceServers);
               //服务器把ICE 服务的信息下发到js创建RTCPeerConnection使用
               //configuration = JSON.parse(data.iceServers);
                          if(data.iceServers.constructor === Object){
                            console.log(" _create  iceServers ---object ---- ", JSON.stringify(data.iceServers));
                            this.configuration = JSON.parse(JSON.stringify(data.iceServers));
                            }else{
                               console.log(" _create  iceServers ---string ---- :", data.iceServers);
                               this.configuration = JSON.parse(data.iceServers);
                          }
              }
              this.Call();
          }else{
            console.log(" _create  offline  ",data.from); 
            }
			},
      Call() {
        //console.log("call");
        this.StartCalled = true;
        var audioenable = "sendrecv";
        var videoenable = "sendrecv";
        var datachannelenable = "true";
        if(this.IsLocalDataChannel == false){
          datachannelenable = "false";
        }
        if(this.IsLocalAudioTrack == false){
           audioenable = "recvonly";
        }
        if(this.IsLocalVideoTrack == false){
          videoenable = "recvonly";
        }
        console.log("call audio ",audioenable," video ",videoenable," datachannel",datachannelenable);
        this.global.sendToServer({
          "eventName": "__call",
           "data": {
             "sessionId": this.sessionId,
             "sessionType": "IE",
              "messageId": this.newGuid(),
              "from": this.meid,
              "to": this.peerid,
              "mode": this.connectmode,
              "source": this.connectsource,
              "datachannel": datachannelenable,
              "audio": audioenable,
              "video": videoenable,
              "user": "admin",
              "pwd": "123456",
              "iceservers": JSON.stringify(this.configuration)
              }
        });
        },
        handleConnectInfo(data){
          console.log("Got ConnectInfo Message:", data.message);
        },
        handleLeave(){
           console.log('handleLeave  --------');
           var that = this;

          if(this.myDataChannel!= null){
                this.myDataChannel.close();
                this.myDataChannel.onopen = null;
                this.myDataChannel.onclose = null;
                this.myDataChannel.onmessage = null;
                this.myDataChannel.onerror = null;
                this.myDataChannel = null;
            }
          if(this.myPeerConnection!= null){
            console.log('handleLeave  --------signalingState = ',this.myPeerConnection.signalingState);
            if(this.myPeerConnection.signalingState=='closed'){
                this.handleRelease();
            }else{
              this.myPeerConnection.getSenders().forEach(sender => {
                 console.log('removeTrack  ',sender);
                  that.myPeerConnection.removeTrack(sender);
              });                      
               this.myPeerConnection.close();   
               
               setTimeout(() => {
                          if(that.myPeerConnection!= null){
                              if(that.myPeerConnection.signalingState=='closed'){
                                  that.handleRelease();
                              }
                          }
              }, 100);

            }

          }
          this.videoplaying = false;
          this.RTCPeerConnectionCreated = false;
          this.StartCalled = false;
          this.IceCandidate.splice(0,this.IceCandidate.length);
        },
        handleRelease(){
          console.log('handleRelease  ========');
          if(this.myPeerConnection!= null){
            this.myPeerConnection.onicecandidate = null;
             this.myPeerConnection.onaddstream = null;
             this.myPeerConnection.ontrack = null;
             this.myPeerConnection.onsignalingstatechange = null;
             this.myPeerConnection.onicegatheringstatechange = null;            
             this.myPeerConnection = null;
          }
          if(this.IsFailCreate == false){
                 this.IsReconnect = true; 
          }
          

        },
        async handleAnswer(data){
              console.log("handleAnswer --- ----   :\n", data);
        },
        async handleOffer(data) {
          var that = this;
          this.IsReconnect = false;
          console.log("Offer --- ---- sdp  :\n", data.sdp);
          if(this.RTCPeerConnectionCreated == false) {
            this.initPeerConnection();
          }
          if(this.RTCPeerConnectionCreated == false) {
            console.log("handleOffer Failed   RTCPeerConnectionCreated = ", this.RTCPeerConnectionCreated);
            this.sendDisconnect();
            this.IsReconnect = false;
            this.IsFailCreate = true;
          }
       
          let nativeRTCSessionDescription = (window.mozRTCSessionDescription || window.RTCSessionDescription );  
          try {
                await  this.myPeerConnection.setRemoteDescription(new nativeRTCSessionDescription({type:'offer', sdp:data.sdp}));
              } catch (err) {
                  console.log("Error when setRemoteDescription-----------------------------",err.message);
                  that.IsReconnect = true;
                  this.IsFailCreate = true;
                  return;
          }


        await this.myPeerConnection.createAnswer().then(function (answer) {
              that.myPeerConnection.setLocalDescription(answer);
              console.log("Answer sdp :\n" + answer.sdp);
              that.global.sendToServer({
                "eventName": "__answer",
                      "data": {
                        "sessionId": that.sessionId,
                        "sessionType": "IE",
                        "messageId": that.newGuid(),
                        "from": that.meid,
                        "to": that.peerid,
                        "type": answer.type,
                        "sdp": answer.sdp
                        }
                        });
    }).catch(function (error) {
             console.log("Error when create  Answer-----------------------------",error.message);
            that.sendDisconnect();
            that.IsReconnect = true;
            this.IsFailCreate = true;
    });

    
      },
			sendDisconnect() {
        this.global.sendToServer({
          "eventName": "__disconnected",
          "data": {
            "sessionId": this.sessionId,
            "sessionType": "IE",
            "messageId": this.newGuid(),
            "from": this.meid,
            "to": this.peerid
            }
            });
      },
      handleCandidate(data) {
        var obj =JSON.parse(data.candidate);
        if(this.myPeerConnection!= null && this.RTCPeerConnectionCreated==true){
           console.log("candidate --- ----   :", obj.candidate);
           var candidate = new RTCIceCandidate({sdpMLineIndex:obj.sdpMLineIndex, candidate:obj.candidate});
           this.myPeerConnection.addIceCandidate(candidate);
           }else{
            this.IceCandidate.push(obj);
             }
      },
      Connect() {
        this.global.sendToServer({
          "eventName": "__connectto",
          "data": {
              "sessionId": this.sessionId,
              "sessionType": "IE",
              "messageId": this.newGuid(),
              "from": this.meid,
              "to": this.peerid
              }
            });
      },
      NewgetUserMedia(constrains,success,error){//访问用户媒体设备的兼容方法
              console.log("NewgetUserMedia----------------");
              
              if(window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia){//最新标准API
              window.navigator.mediaDevices.getUserMedia(constrains).then(success).catch(error);
              } else if (window.navigator.webkitGetUserMedia){//webkit内核浏览器
              window.navigator.webkitGetUserMedia(constrains).then(success).catch(error);
              } else if (window.navigator.mozGetUserMedia){//Firefox浏览器
              window.navagator.mozGetUserMedia(constrains).then(success).catch(error);
              } else if (window.navigator.getUserMedia){//旧版API
              window.navigator.getUserMedia(constrains).then(success).catch(error);
              }else if (window.navigator.msGetUserMedia){
              window.navigator.msGetUserMedia(constrains).then(success).catch(error);
              }else{
                  this.CheckedgetUserMedia = false;
                  this.IsLocalAudioTrack = false;				
                  console.log("Your browser no getUserMedia function");


              }
      },
      streamHandler(mystream) {
         this.CheckedgetUserMedia = true;
        // window.localStream  = mystream;
         this.stream = mystream ;
         console.log("streamHandler  stream = ",this.stream );
         if ("getTracks" in mystream) {
           console.log('streamHandler getTracks');
           if(mystream!= null){
              mystream.getTracks().forEach(track => {
                console.log('streamHandler getTracks ',track);
                if(track.kind==="audio"){
                  this.IsLocalAudioTrack = true;
                  }else if(track.kind==="video"){
                    this.IsLocalVideoTrack = true;
                  }
               });
             }
          }
            if(this.mute == true){                
               this.iconmute='mdi-microphone';
            }else{               
               this.iconmute='mdi-microphone-off';
            }
           this.Connect();
      },
      errorHandler(error) {
        console.log("errorHandler",error);
        this.CheckedgetUserMedia = true;
        this.iconmute='mdi-microphone-outline';
         this.mute = false;
         this.Connect();
        
      },


      shareCamera(){
       
         if(this.isWeixinminiProgram==true){       
            console.log("Home shareCamera   ",this.serno);
            var path = '/share/share?'+"serno="+this.serno+"&name="+this.name;           
            this.wx.miniProgram.navigateTo({url: path})       
            
         }


      },
      setlocalspeak(){
        var that = this;
        if(this.myPeerConnection !=null){
          this.speak =!this.speak;
          if(this.speak == true){
                
             this.iconspeak='mdi-volume-high'
          }else{
               
            this.iconspeak='mdi-volume-low'
          }
          if(this.remoteVideo!=null){
                     if(this.remoteVideo.muted ==true){
                         this.remoteVideo.muted= false;
                     }
          }
          this.myPeerConnection.getReceivers().forEach(function(receiver){
            
            if(receiver.track!= null && receiver.track.kind ==='audio'){
              receiver.track.enabled = that.speak;
              console.log("PeerConnection  setlocalspeak    -------------------------------------- ",receiver);
              }
            });
            }
      },
      setlocalaudio(){
           var that = this;
           if(this.myPeerConnection !=null){
             if( this.IsLocalAudioTrack == false){
                 this.iconmute='mdi-microphone-outline';
                 this.mute = false;
             }else{

            
             this.mute =!this.mute;
            if(this.mute == true){

                 
               this.iconmute='mdi-microphone';
            }else{
                 
               this.iconmute='mdi-microphone-off';
            }
            
             this. myPeerConnection.getSenders().forEach(function(sender){
               
               if(sender.track!= null && sender.track.kind ==='audio'){
                 sender.track.enabled = that.mute;
                 console.log("PeerConnection  setlocalaudio    -------------------------------------- ",sender);
                 }
                 });
            }
             }
      },
      initPeerConnection(){
        try {
          //console.log('initPeerConnection create PeerConnection configuration =',this.configuration);
            this.myPeerConnection = new RTCPeerConnection(this.configuration);
            if(this.myPeerConnection!=null){
              if ("addTrack" in this.myPeerConnection) {
                if(this.stream!= null){
                  this.stream.getTracks().forEach(track => {
                    this.myPeerConnection.addTrack(track, this.stream);
                      console.log('PeerConnection use addTrack type',track.kind);
                      if(track.kind==='audio'){
                            track.enabled = this.mute;

                                if(this.mute == true){                                    
                                  this.iconmute='mdi-microphone';
                                }else{                                  
                                  this.iconmute='mdi-microphone-off';
                                }

                        }
                        });
               }else{
                          console.log('PeerConnection  addTrack  stream is null');
               }
              } else {
                if(this.stream!= null){
                     this.myPeerConnection.addStream(this.stream);
                     this.stream.getTracks().forEach(track => {
                       console.log('PeerConnection use addStream type',track.kind);
                       if(track.kind==='audio'){
                         track.enabled = this.mute;
                         }
                         });
                    }else{
                      console.log('PeerConnection  addStream is null');
                      }
              }
              if ("ontrack" in this.myPeerConnection) {
                this.myPeerConnection.ontrack = this.handleRemoteTrackAdded;
              } else {
                  this.myPeerConnection.onaddstream = this.handleRemoteStreamAdded;
              }
              this.myPeerConnection.onicecandidate = this.handleIceCandidate;
              this.myPeerConnection.oniceconnectionstatechange = this.handleIceConnectionStateChangeEvent;
              this.myPeerConnection.onicegatheringstatechange = this.handleIceGatheringStateChangeEvent;
              this.myPeerConnection.onsignalingstatechange = this.handleSignalingStateChangeEvent;
              this.myPeerConnection.onconnectionstatechange =this.handleConnectionStateChangeEvent;
              this.myPeerConnection.onnegotiationneeded = this.handleNegotiationNeededEvent;
              if(this.IsLocalDataChannel == true){
                  try {
                    this.myDataChannel = this.myPeerConnection.createDataChannel("mydatachannel");
                    if(this.myDataChannel){
                      this.myDataChannel.onopen = this.handleDataChannelOnOpen;
                      this.myDataChannel.onclose = this.handleDataChannelOnClose;
                      this.myDataChannel.onmessage = this.handleDataChannelOnMessage;
                      this.myDataChannel.onerror = this.handleDataChannelOnError;
                      }
                } catch (error) {
                        console.log("data_channel_create_error  ");
                }
              }
              console.log('Sucessed to create PeerConnection');
              this.RTCPeerConnectionCreated = true;
            }else{
              console.log('Failed to create PeerConnection');
              this.RTCPeerConnectionCreated = false;
            }
        }catch (e) {
          console.log('Failed to create PeerConnection, exception: ' + e.message);
          this.RTCPeerConnectionCreated = false;
          return;
          }
      },
      handleRemoteTrackAdded(e) {
        //console.log("handleRemoteTrackAdded",JSON.stringify(e));
        if(this.remoteVideo!=null){
              //console.log("handleRemoteTrackAdded attachStream srcObject stream id = ",e.streams[0].id);
              e.streams[0].getTracks().forEach(track => {
                console.log('handleRemoteTrackAdded  type',track.kind);
                if(track.kind ==='audio'){
                  track.enabled = this.speak;
                  }
              });
              this.remoteVideo.srcObject = e.streams[0];
              if(this.IsSystemAudioDeviceOK==false){
                if(this.isIos ){
                //this.remoteVideo.muted = true;
                }
              }
              
          }else{
                     console.log("handleRemoteTrackAdded  video view is null");
          }
      },
      handleRemoteStreamAdded(e) {
        if(this.remoteVideo!=null){
          if ("srcObject" in this.remoteVideo) {
            console.log("handleRemoteStreamAdded attachStream srcObject stream id = ",e.stream.id);
            this.remoteVideo.srcObject =  e.stream;
            e.stream.getTracks().forEach(track => {
                //console.log('handleRemoteTrackAdded  type',track.kind);
                if(track.kind ==='audio'){
                  track.enabled = this.speak;
                  }
                });
            
          } else {
            var objUrl=  this.getObjectURL( e.stream);
            console.log("handleRemoteStreamAdded attachStream getObjectURL stream id = ",e.stream.id);
            this.remoteVideo.src = objUrl;
            e.stream.getTracks().forEach(track => {
                //console.log('handleRemoteTrackAdded  type',track.kind);
                if(track.kind ==='audio'){
                  track.enabled = this.speak;
                  }
                });
            
            }
         }
      },
			handleIceCandidate(event) {
        if (event.candidate) {
          console.log('onicecandidate: ' + event.candidate.candidate);
          this.global.sendToServer({
            "eventName": "__ice_candidate",
            "data": {
              "sessionId": this.sessionId,
              "sessionType": "IE",
              "messageId": this.newGuid(),
              "to": this.peerid,
              "from": this.meid,
              "candidate": JSON.stringify({'candidate':event.candidate.candidate,'sdpMid':event.candidate.sdpMid,'sdpMLineIndex':event.candidate.sdpMLineIndex})
              }
              });
              }
      },
      handleIceConnectionStateChangeEvent(event) {
        if(this.myPeerConnection == null){
           return;
        }
        
        //console.log("*** ICE connection state changed to " + this.myPeerConnection.iceConnectionState,event);
        if(event.target == this.myPeerConnection){
           console.log("*** ICE connection state changed to ",event.target.iceConnectionState);

         switch(this.myPeerConnection.iceConnectionState) {
          case "closed":          
          break;
          case "failed":
          case "disconnected":         
          break;
          case "connected":        
                            
            break;
          }


        }
        
      },
      handleIceGatheringStateChangeEvent(event) {
        if(this.myPeerConnection == null){
          return;
          }
          if(event.target == this.myPeerConnection){
            console.log("*** ICE gathering state changed to: " + event.target.iceGatheringState);
          }
        
          
        },
        handleSignalingStateChangeEvent(event) {
           if(this.myPeerConnection == null){
              return;
           }
          if(event.target == this.myPeerConnection){
          console.log("*** WebRTC signaling state changed to: " + event.target.signalingState);
          switch(this.myPeerConnection.signalingState) {
            case "closed":              
              this.handleRelease(); 
              break;
              }
          }
      },
       handleNegotiationNeededEvent(event) {
         if(event.target == this.myPeerConnection){
                 console.log("***handleNegotiationNeededEvent  " + event);
          }
       },
        handleConnectionStateChangeEvent(event) {
        
        if(this.myPeerConnection == null){
              return;
        }
         if(event.target == this.myPeerConnection){
              console.log("***Connection State Change  connectionState  " + this.myPeerConnection.connectionState);
         }
        switch(this.myPeerConnection.connectionState) {
            case "connected":
              // The connection has become fully connected
              this.videoplay();   
              break;
            
            case "disconnected":
              break;
            case "failed":
           if(this.RTCPeerConnectionCreated == true){
                 this.handleLeave();
                 this.sendDisconnect();
            }     
                
              // One or more transports has terminated unexpectedly or in an error
              break;
            case "closed":
              this.handleRelease(); 
              break;
        }
        

      },
      handleDataChannelOnOpen() {
        console.log('handleDataChannelOnOpen');
      },
      handleDataChannelOnClose(event) {
        console.log('handleDataChannelOnClose ',event);
      },
      handleDataChannelOnMessage(message) {
        console.log('handleDataChannelOnMessage ',message);
        var json;
         json = JSON.parse(message.data);
         if (json.type === '__file') {
           console.log('handleDataChannelOnClose recive message data',json.data);
           }else if (json.type === '__msg') {
             console.log('handleDataChannelOnClose recive message data',json.data);
            } else {
              console.log('handleDataChannelOnClose recive message data',json.data);
              }
      },
      handleDataChannelOnError(err) {
        console.log('handleDataChannelOnError ',err);
      },
      pageResize(){

       var loading = document.getElementById('loading'); 
       var videos = document.getElementById('videos');
       var remoteVideo = document.getElementById("remoteVideo");
       var clientWidth = document.documentElement.clientWidth;
       var clientHeight = document.documentElement.clientHeight;
       var videoheight = (9*document.documentElement.clientWidth/16);
       if(videoheight>clientHeight/2){
         videoheight = clientHeight/2

       }

       videos.style.width = clientWidth+'px';
       videos.style.height = videoheight+'px';


      loading.style.left= (document.documentElement.clientWidth/2)-25+'px';
      loading.style.top= ((videoheight)/2-25)+'px';

   
   
        
  

      remoteVideo.style.width = clientWidth+'px';
      remoteVideo.style.height = videoheight+'px';


       },
      videoplay(){

        var that = this;
        if(this.videoplaying == false){
           this.videoplaying = true;
          var  playPromise  = this.remoteVideo.play();
          if (playPromise) {
                          playPromise.then(() => {
                            that.videoplaying = true;
                            console.log("<video>  play");

                          }).catch((e) => {
                              that.videoplaying = false;
                              console.log("<video> play error",e.message);
                          });
          }
      }
      },
      OnVideoPlay() {
         console.log("<video> event OnVideoPlay------");
  
      },
      OnVideoLoadedMetaData() {
        console.log("<video> event OnVideoLoadedMetaData----------------");
        this.videoplay();
        
      },
      OnVideoCanPlay() {
         console.log("<video> event OnVideoCanPlay----------------");
         console.log("<video>  OnVideoCanPlay-----videoWidth = "+this.remoteVideo.videoWidth);
         console.log("<video>  OnVideoCanPlay-----videoHeight = "+this.remoteVideo.videoHeight);
         console.log("<documentElement>  OnVideoCanPlay------clientWidth = "+document.documentElement.clientWidth);
         console.log("<documentElement>  OnVideoCanPlay------clientHeight = "+document.documentElement.clientHeight);
        var loading = document.getElementById('loading'); 
        loading.style.visibility="hidden";
        if(this.remoteVideo.muted == true){
                // if(this.isIos ==false){
                   this.remoteVideo.muted = false;
                 // }
        }
      },
      OnVideoError() {
         console.log("<video> event OnVideoError----------------");
      },
      OnVideoLoadedData(){
         console.log("<video> event OnVideoLoadedData----------------");
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.article {
        height: 100%;
        display: flex;
        flex-direction: column;
}
.divtools{	
    width: 100%;
		height: 30px;
}
.divhead{
    --width: 100%;		
    background-color: #000000;
    width: 100%;
		height: 100px;
}
.arc {
  --width: 100%;
  --height: 100%;
  position: absolute;
  left: calc(var(--width)/2 - 25px);
  top:  calc(var(--height)/2 - 25px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 2px solid #ffea29;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: rt 2s infinite linear;
}
.arc::before {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border-top: 2px solid #8d29ff;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: rt 4s infinite linear reverse;
  content: "";
}
.arc::after {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  border-top: initial;
  border-left: initial;
  border-right: initial;
  animation: cw 1s infinite;
  content: "";
  background:  snow;
}
@keyframes rt {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes cw {
  0% {
    width: 0;
    height: 0;
  }
  75% {
    width: 20px;
    height: 20px;
  }
  100% {
    width: 0;
    height: 0;
  }
}

.scale{background: #ddd; width: 100%; height: 3px; position: relative;margin:0px 0px 0px 0px;}

.scale span{background:#aaa;width:8px;height:16px;position:absolute;left:-2px;top:-6px;cursor:pointer; border-radius: 3px;}

.scale div{background: #66D9EF; position: absolute; height: 3px; width: 0; left: 0; bottom: 0; }
.rhvideo {
        position: absolute;
        left: 0px;
        top:  0px;
        width: 100%;
        height: 100%;
				color: #000000;
				background-color: #000000;
        object-fit:fill;
        muted: "true";
				preload:"auto";
				autoplay:"autoplay";
				x-webkit-airplay:"true";
				playsinline:"true";
				controls:"false";
				webkit-playsinline:"true";
				x5-video-player-type:"h5";
				x5-video-player-fullscreen:"true" ;
				x5-video-orientation:"portraint";						
	}
</style>
